<template>
  <div style="height: 100vh;">
    <b-row class="justify-content-center">
      <b-col
        v-if="survey_status == null"
        class=" mt-1"
        md="4"
      >
        <b-alert
          class="text-center mt-1"
          show
          variant="danger"
        >
          <h4 class="alert-heading">
            {{$t('survey_management.survey_not_found')}}
          </h4>
        </b-alert>
      </b-col>
      <b-col
        v-if="survey_status == true"
      >
        <survey-creator
          :survey_type="survey_type"
          :survey_id="survey_id"
          :survey_data="survey_data"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SurveyCreator from '@/views/custom_app/client/survery_management/components/SurveyCreator.vue'
import {
  BAlert, BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import OrganizationRenderer
from '@/views/custom_app/client/organization_management/components/OrganizationRenderer.vue'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    OrganizationRenderer,
    BAlert,
    SurveyCreator,
  },
  data() {
    return {
      survey_data: null,
      survey_status: false,
      survey_id: router.currentRoute.query.id,
      survey_type: router.currentRoute.query.survey_type,
    }
  },
  created() {
    this.check_survey()
  },
  methods: {
    check_survey() {
      if (this.survey_type === 'one') {
        useJwt.NodeSurveysDetail(this.survey_id)
          .then(response => {
            this.survey_status = true
            this.survey_data = response.data.survey
          })
          .catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('core.error'),
                icon: 'CheckCircleIcon',
                variant: 'danger',
              },
            })
          })
      } else if (this.survey_type === 'group') {
        useJwt.SurveyGroupsDetail(this.survey_id)
          .then(response => {
            this.survey_status = true
            this.survey_data = response.data.survey
          })
          .catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('core.error'),
                icon: 'CheckCircleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>
<style>
.svc-creator__banner {
  display: none;
}
.svc-creator__area.svc-creator__area--with-banner {
  height: 100%;
}

.svc-toolbox {
  background: white !important;
}

</style>
