<template>
  <div id="surveyCreator" />
</template>

<script>
import { SurveyCreator } from 'survey-creator-knockout'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'

import 'survey-creator-core/survey-creator-core.i18n'
import 'survey-creator-core/i18n/french'
import 'survey-creator-core/i18n/german'
import 'survey-creator-core/i18n/italian'
import 'survey-creator-core/i18n/turkish'
import { editorLocalization } from 'survey-creator-core'
import { surveyLocalization } from 'survey-core'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from "@/router";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showTranslationTab: true,
  showThemeTab: true,
  showJSONEditorTab: false,

}

// const defaultJson = {
//   pages: [{
//     name: 'Name',
//     elements: [{
//       name: 'FirstName',
//       title: 'Enter your first name:',
//       type: 'text',
//     }, {
//       name: 'LastName',
//       title: 'Enter your last name:',
//       type: 'text',
//     }],
//   }],
// }

export default {
  name: 'SurveyCreator',
  props: {
    survey_data: {
      type: Object,
    },
    survey_id: {
      type: String,
    },
    survey_type: {
      type: String,
    },
  },
  data() {
    return {
      creator: null,
    }
  },
  destroyed() {
    this.creator.dispose()
  },
  created() {
  },
  mounted() {
    this.creator = new SurveyCreator(creatorOptions)
    this.creator.toolbox.forceCompact = false
    this.creator.locale = localStorage.getItem('current_locale') || 'en'
    // bu dosyanın veri tabanına embeded olarak ayarlaması kısmını kapatır
    this.creator.onPropertyEditorCreated.add(function(sender, options) {
      // storeDataAsText alanı gizleniyor
      if (options.obj.getType() === "file" && options.property.name === "storeDataAsText") {
        options.editor.visible = false // storeDataAsText özelliğini gizle
      }
      // navigateToUrl alanı gizleniyor
      if (options.obj.getType() === "survey" && options.property.name === "navigateToUrl") {
        options.editor.visible = false; // navigateToUrl özelliğini gizle
      }

      // navigateToUrlOnCondition alanı gizleniyor
      if (options.obj.getType() === "survey" && options.property.name === "navigateToUrlOnCondition") {
        options.editor.visible = false; // navigateToUrl özelliğini gizle
      }
    })
    // Bu olay her yeni soru eklendiğinde çalışır
    this.creator.onQuestionAdded.add(function (survey, options) {
      if (options.question.getType() === "file") {
        options.question.storeDataAsText = false;
      }
    })
    editorLocalization.currentLocale = localStorage.getItem('current_locale') || 'en'
    surveyLocalization.supportedLocales = [
      'tr',
      'en',
      'fr',
      'de',
      'pt',
      'it',
      'es',
      'ja',
      'ru',
      'ko',
    ]
    if (this.survey_data !== null) {
      this.creator.text = JSON.stringify(this.survey_data)
    }
    this.creator.saveSurveyFunc = (saveNo, callback) => {
      const formData = new FormData()
      formData.append('survey', this.creator.text)
      if (this.survey_type === 'one') {
        useJwt.updateNodeSurveys(this.survey_id, formData)
          .then(response => {
          })
          .catch(error => {
            console.log(error.response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('core.error'),
                icon: 'CheckCircleIcon',
                variant: 'danger',
                text: 'Geri bildirim güncellenemedi!',
              },
            })
          })
      } else if (this.survey_type === 'group') {
        useJwt.updateSurveyGroups(this.survey_id, formData)
          .then(response => {
          })
          .catch(error => {
            console.log(error.response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('core.error'),
                icon: 'CheckCircleIcon',
                variant: 'danger',
                text: 'Geri bildirim güncellenemedi!',
              },
            })
          })
      }

      // window.localStorage.setItem('survey-json', creator.text)
      callback(saveNo, true)
      // saveSurveyJson(
      //     "https://your-web-service.com/",
      //     creator.JSON,
      //     saveNo,
      //     callback
      // );
    }

    // creator.onUploadFile.add((_, options) => {
    //   const formData = new FormData();
    //   options.files.forEach(file => {
    //     formData.append(file.name, file);
    //   });
    //   fetch("https://example.com/uploadFiles", {
    //     method: "post",
    //     body: formData
    //   }).then(response => response.json())
    //     .then(result => {
    //       options.callback(
    //         "success",
    //         // A link to the uploaded file
    //         "https://example.com/files?name=" + result[options.files[0].name]
    //       );
    //     })
    //     .catch(error => {
    //       options.callback('error');
    //     });
    // });
    this.creator.render('surveyCreator')
  },
}

// function saveSurveyJson(url, json, saveNo, callback) {
//   fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     body: JSON.stringify(json)
//   })
//   .then(response => {
//     if (response.ok) {
//       callback(saveNo, true);
//     } else {
//       callback(saveNo, false);
//     }
//   })
//   .catch(error => {
//     callback(saveNo, false);
//   });
// }
</script>

<style scoped>
#surveyCreator {
  height: 100vh;
  width: 100vw;
}

</style>
